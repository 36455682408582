// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kMNtG9kr,._8ZMNPVAH{position:relative}@media(hover:hover){._8ZMNPVAH:hover .xSB3TMMJ{opacity:1}}@media(hover:none){._8ZMNPVAH:active .xSB3TMMJ{opacity:1}}._0n6yJb4d{max-height:18.75rem;opacity:0;pointer-events:none}.pE5HnTLs{--rotate-y:0deg;left:var(--shift)}.PmZSihdG{--rotate-y:180deg;right:var(--shift)}.PmZSihdG,.pE5HnTLs{--icon-size:1.25rem;--shift:-0.75rem;border:1px solid var(--color-gray-3);padding:.13rem;position:absolute;top:50%;transform:translateY(-50%) rotateY(var(--rotate-y));transition:.1s linear;transition-property:opacity,visibility;z-index:3}@media(max-width:667.98px){.PmZSihdG,.pE5HnTLs{display:none}}.xSB3TMMJ{background:var(--color-bg)!important;cursor:not-allowed!important;opacity:0}.MWcFcKro{left:50%;position:absolute;top:50%;transform:translate(-50%,-50%)}.pwffl-rH{transform:scale(2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "kMNtG9kr",
	"content": "_8ZMNPVAH",
	"navDisabled": "xSB3TMMJ",
	"contentHidden": "_0n6yJb4d",
	"navPrev": "pE5HnTLs",
	"navNext": "PmZSihdG",
	"placeholder": "MWcFcKro",
	"placeholderSpinner": "pwffl-rH"
};
module.exports = ___CSS_LOADER_EXPORT___;
