// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R35tiOM6{padding:0 0 2.5rem}@media(min-width:667.98px){.R35tiOM6{padding:0 0 6.25rem}}.MdoSEPhz{margin-bottom:1.25rem}@media(min-width:667.98px){.MdoSEPhz{margin-bottom:3.75rem}}.NoZY-qGw{flex-direction:column}.NoZY-qGw,.hdhQxedp{align-items:center;display:flex}.hdhQxedp{background-color:var(--color-text);border-radius:var(--border-radius);height:11.5rem;justify-content:center;margin-bottom:var(--spacing-lg);width:100%}@media(max-width:667.98px){.hdhQxedp{height:9.38rem}}.hdhQxedp img{height:37%;-o-object-fit:contain;object-fit:contain;width:50%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "R35tiOM6",
	"partners": "MdoSEPhz",
	"partner": "NoZY-qGw",
	"partnerImg": "hdhQxedp"
};
module.exports = ___CSS_LOADER_EXPORT___;
